import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/game.png";
import projImg3 from "../assets/img/projImg3.png";
import projImg4 from "../assets/img/projImg4.png";
import projImg5 from "../assets/img/projImg5.png";
import projImg6 from "../assets/img/projImg6.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projectsWeb = [
    {
      title: "Création site vitrine",
      description: "Design & Développement",
      imgUrl: projImg3,
    },
    {
      title: "Création e-commerce",
      description: "Design & Développement",
      imgUrl: projImg1,
    },
    {
      title: "Création jeu de dé javascript",
      description: "Design & Développement Javascript",
      imgUrl: projImg2,
    },
    {
      title: "Création jeu et serveur MMO en ligne",
      description: "Design & Développement PHP MYSQL JS CSS",
      imgUrl: projImg1,
    },
    {
      title: "Création APP jeu de calcul ludique",
      description: "Développement iOS",
      imgUrl: projImg1,
    },
    {
      title: "Pilotage domotique raspberry Pi",
      description: "Développement électronique",
      imgUrl: projImg1,
    },
  ];
  const projectsIos = [
    {
      title: "Création site vitrine",
      description: "Design & Développement",
      imgUrl: projImg3,
    },
    {
      title: "Création e-commerce",
      description: "Design & Développement",
      imgUrl: projImg1,
    },
    {
      title: "Création jeu de dé javascript",
      description: "Design & Développement Javascript",
      imgUrl: projImg3,
    },
    {
      title: "Création jeu MMO en ligne",
      description: "Design & Développement PHP",
      imgUrl: projImg1,
    },
    {
      title: "Création APP jeu de calcul ludique",
      description: "Développement iOS",
      imgUrl: projImg1,
    },
    {
      title: "Pilotage domotique raspberry Pi",
      description: "Développement électronique",
      imgUrl: projImg1,
    },
  ];
  const projectsShop = [
    {
      title: "Vacherins",
      description: "Création artisanale de vacherins",
      imgUrl: projImg4,
    },
    {
      title: "Petits gâteaux de Noël",
      description: "Plusieurs sortes de gâteaux de Noël",
      imgUrl: projImg5,
    },
    {
      title: "Couronnes de l'avent",
      description: "Création de couronnes de l'avent",
      imgUrl: projImg6,
    }
    
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Ce ne sont évidement pas tous mes projets, mais ceux ci-après sont ceux qui m'ont marqué dans ma vie de développeur, pour en savoir d'avantage, n'hésitez pas à me contacter via le formulaire </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Création web</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Développement mobile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Boutique E-commerce</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projectsWeb.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>A venir</p>
                      
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Création d'une boutique en ligne familiale de vente de pâtisserie et de gâteaux de saison fait maison!</p>
                      <Row>
                        {
                          projectsShop.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
